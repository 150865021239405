/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-03-14",
    versionChannel: "nightly",
    buildDate: "2025-03-14T00:06:28.263Z",
    commitHash: "7e4eefe8d2923b2adf804ae673b348102e656acc",
};
